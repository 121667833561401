import * as React from "react";
import { useFrame } from "react-three-fiber";
import { OrthographicCamera } from "@react-three/drei";

export function Camera(props: { dynamic?: boolean }) {
  const refCamera = React.createRef<any>();

  useFrame(() => {
    if (props.dynamic) {
      if (window.innerWidth >= 1280) {
        refCamera.current.position.x = -4;
        refCamera.current.position.y = 1;
        refCamera.current.scale.x = 0.7;
        refCamera.current.scale.y = 0.7;
      } else {
        refCamera.current.position.x = 0;
        refCamera.current.position.y = 0;
        refCamera.current.scale.x = 1;
        refCamera.current.scale.y = 1;
      }
    }
  });

  return (
    <OrthographicCamera
      ref={refCamera}
      makeDefault
      far={1000}
      near={0}
      zoom={70}
      position={[0, 0, 100]}
    />
  );
}
