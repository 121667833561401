import * as THREE from "three";
import { extend } from "react-three-fiber";

class TriangleMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        time: { value: 1 },
        radius: { value: 0 },
        color_r: { value: 1 },
        color_g: { value: 1 },
        color_b: { value: 1 },
        opacity: { value: 1 },
        sizeScale: { value: 1 },
      },
      vertexShader: `uniform float time;
      uniform float radius;
      uniform float sizeScale;
      attribute float size;
      attribute float d;
      void main() {
        float loopTime = mod(time, 10.);

        if(time > 10.) {
          loopTime = loopTime - 1.;
        }
        
        float PI = 3.1415926538;
        float x = position.x;
        float y = position.y;
        float z = position.z;

        float r = z;
        float currentRadius = pow(0.5, sqrt(pow(2., x) + pow(2., y)));

        if(loopTime < 5.) {
            r = r + 15. * currentRadius * loopTime / 10. * d;
        } else {
            r = r + 15. * currentRadius * (5. - (loopTime - 5.)) / 10. * d;
        }

        if(r > 1.) {
            r = 0.;
        } 

        if(r < 0.) {
            r = 0.;
        }

        vec3 pos = vec3(
          x * r,
          y * r,
          0
        );
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 2.0 );
        gl_PointSize = size * sizeScale;
      }`,
      fragmentShader: `uniform float time;
      uniform float color_r;
      uniform float color_g;
      uniform float color_b;
      uniform float opacity;
      void main() {
        vec4 color = vec4(color_r, color_g, color_b, opacity);
        
        float r = 0.0, delta = 0.0, alpha = 1.0;
        vec2 cxy = 2.0 * gl_PointCoord - 1.0;
        r = dot(cxy, cxy);
        if (r > 1.0) {
            discard;
        }
        gl_FragColor = color * (alpha);
      }`,
    });
  }
}

extend({ TriangleMaterial });
