import * as React from "react";
import { Canvas, useThree } from "react-three-fiber";
import "./styles.scss";
import { Camera } from "../Camera";
import { CurvedTriangle } from "../CurvedTriangle";
import { hexToRgb, interpolateRgb } from "../../../utils";
import { useWindowSize } from "../../../hooks";

const [x0, y0, z0] = [0, 0, 0];

const r0 = 1 * 2.6;

function Animation3Content() {
  const [camera, setCamera] = React.useState<THREE.Camera | null>(null);

  const size = useWindowSize();

  const radius = React.useMemo(() => {
    const circle = document
      .querySelector("svg.investment-svg circle")
      ?.getBoundingClientRect();
    if (circle && camera) {
      return (2 * r0 * circle.width) / 370;
    }
    return r0;
  }, [size, camera]);

  useThree((asd) => {
    if (asd.camera !== camera) setCamera(asd.camera);
  });

  return (
    <>
      <CurvedTriangle
        position={[x0, y0, z0]}
        points={[
          (1 * (2 * Math.PI)) / 7 + Math.PI / 2,
          (3 * (2 * Math.PI)) / 7 + Math.PI / 2,
          (6 * (2 * Math.PI)) / 7 + Math.PI / 2,
        ]}
        radius={radius}
        opacity={() => {
          return 0.25;
        }}
        color={() => {
          return hexToRgb("c759f9");
        }}
      />
      <CurvedTriangle
        position={[x0, y0, z0]}
        points={[
          Math.PI / 2,
          (2 * (2 * Math.PI)) / 7 + Math.PI / 2,
          (5 * (2 * Math.PI)) / 7 + Math.PI / 2,
        ]}
        radius={radius}
        opacity={() => {
          return 0.5;
        }}
        color={() => {
          return hexToRgb("ea87ab");
        }}
      />
      <CurvedTriangle
        position={[x0, y0, z0]}
        points={[
          Math.PI / 2,
          (2 * (2 * Math.PI)) / 7 + Math.PI / 2,
          (4 * (2 * Math.PI)) / 7 + Math.PI / 2,
        ]}
        radius={radius}
        opacity={() => {
          return 1;
        }}
        color={() => {
          return hexToRgb("f8a562");
        }}
      />
      <Camera />
    </>
  );
}

export function Animation3() {
  return (
    <div className="animation-3">
      <Canvas style={{ background: "transparent" }}>
        <Animation3Content />
      </Canvas>
    </div>
  );
}
