import * as React from "react";
import { Link, useLocation } from "react-router-dom";

type NavLinkProps = {
  children?: React.ReactNode;
  to: string;
};

export function NavLink(props: NavLinkProps) {
  const location = useLocation();
  return (
    <div
      className={`nav-link ${location.pathname === props.to ? "active" : ""}`}
    >
      <Link to={props.to}>{props.children}</Link>
    </div>
  );
}
