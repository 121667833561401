export const scientists = [
  {
    title:
      "“One of the smartest, knowledgeable and a well-connected investor out there”",
    caption:
      "Ajay and I have known each other for many years and he has been an early supporter of Covariant. Ajay is one of the smartest, knowledgeable and a well-connected deep tech investor out there. I am glad to have his support and also to support him and Frontiers Capital.",
    author: "Pieter Abbeel",
    occupation:
      "Professor UC Berkeley, Robotics & Machine Learning; Founder Covariant",
    image: "./scientist/pieter-abbeel.jpeg",
  },
  {
    title: "“Ajay has been helping me and the team from day 0”",
    caption:
      "It’s rare to find someone who can think deep technical ideas and also help on shaping a business venture. Ajay is one of those rare people. He has a real sense for creative vision and just as keen an eye for operating details. I am glad to have him on my side and also to support him and Frontiers Capital.",
    author: "Josh Tenenbaum",
    occupation:
      "Professor MIT, Founded field of Computational Psychology; Founder CSM",
    image: "./scientist/josh-tenenbaum.jpeg",
  },
  {
    title:
      "Frontiers Capital has been super helpful in building Adept from the earliest days",
    caption:
      "Ajay at Frontiers Capital has been super helpful in building Adept from the earliest days. Ajay knows many folks in ML, speaks the technical language, and has the patience required for deep tech. He’s helped in several things such as hiring senior leadership. I appreciate his long-term mindset.",
    author: "DAVID LUAN",
    occupation: "Co-Built DALL-E, GPT-2, GPT-3, PaLM, CLIP; Founder Adept AI",
    image: "./scientist/david-luan.jpeg",
  },
  {
    title: "“Truly understands what is required to build a deep tech startup”",
    caption:
      "Ajay is one of a kind VC who truly understands what is required to build a deep tech startup. I am glad to be his supporter and a long time friend.",
    author: "David Eagleman",
    occupation:
      "Professor Stanford, Neuroscience; Internationally best selling Author; Founder Neosensory",
    image: "./scientist/david-eagleman.jpeg",
  },
  {
    title: "“Authentic and highly trusted partner”",
    caption:
      "Ajay was one of the first investors I reached out to when I started working on Eigen Layer. Ajay has been a an authentic and highly trusted partner for us from the first day. Ajay brings an exceptional ability at thinking through the academic-to-startup transition along with an excellent network in both worlds. I have since recommended Ajay to my academic colleagues across MIT, Stanford, Princeton and USC; and have been delighted to see many of them chose to work with and benefit from their interactions with Ajay!",
    author: "SREERAM KANNAN",
    occupation:
      "Professor U of Washington, Information Theory & Blockchain; Founder Eigen Layer",
    image: "./scientist/sreeram-kannan.jpeg",
  },
  {
    title: "“Deep on technical things but also grounded on the business side”",
    caption:
      "Ajay and I were connected a few years back around the topic of open-endedness in AI. I have always been impressed by his ability to balance deep technical understanding with grounded business intuition. He is not a typical investor - at one AI conference, we ended up chatting for over 4 hours on all things AI! I'm grateful for his friendship and highly enjoy supporting the Frontiers Capital team.",
    author: "Ken Stanley",
    occupation: "Professor UCF, Pioneer of Evolutionary Algorithms; Stealth",
    image: "./scientist/kenneth-stanley.jpeg",
  },
  {
    title: "“He was the first investor I reached out to share our idea”",
    caption:
      "When I first spoke to Ajay, I was struck by his deep knowledge on our group’s past research work. And when I thought of starting Babylon, he was the first investor I reached out to share our idea. He helped us think through a lot of things from pitch to product. He is one of the key reason why the team started on the Babylon journey as it is. In our highly oversubscribed round, I made sure to have Ajay’s full support in this journey.",
    author: "David Tse",
    occupation:
      "Professor Stanford, Information Theory & Blockchain; Founder Babylon",
    image: "./scientist/david-tse.jpeg",
  },
  {
    title: "“I am always amazed by his thoughtfulness”",
    caption:
      "Ajay is a friend, mentor and a calm and grounded presence in the chaos of startup-life. He can always be relied on to share his insights in a reasoned and constructive manner on all kinds of topics: technical, HR, corporate governance. He is equally deep at technical matters as on personalities and human frailties. It is my great privilege to know Ajay as a friend and even more fortunate to have him as a backer.",
    author: "Pramod Viswanath",
    occupation:
      "Professor Princeton, Deep Learning, Blockchain; Founder WitnessChain",
    image: "./scientist/pramod-viswanath.jpeg",
  },
  {
    title: "“Ajay goes extra mile to genuinely help you”",
    caption:
      "Ajay and I connected through Stanford Prof. Chelsea Finn. Ajay has been helping the Emancro team before we even incorporated and has always made himself available. It’s refreshing to meet an investor who not only understands technology, but also goes extra mile to genuinely help you before even being an investor",
    author: "Frederik Ebert",
    occupation: "PhD UC Berkeley, Robotics & Machine Learning; Founder Emancro",
    image: "./scientist/frederik-ebert.jpeg",
  },
  {
    title: "“I would absolutely recommend Ajay to any technical founder”",
    caption:
      "Ajay is one of our earliest supporters. He not only helps us strategize about our product and hiring plan, he also doesn’t mind rolling up his sleeves and jumping in to edit job descriptions and product marketing materials. I definitely recommend Ajay to technical founders starting their journeys.",
    author: "Michelle Lee",
    occupation: "Professor NYU, Robotics & Machine Learning; Founder Medra AI",
    image: "./scientist/michelle-lee.png",
  },
];
