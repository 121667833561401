import * as React from "react";
import { Canvas, useThree } from "react-three-fiber";
import "./styles.scss";
import { Implosion } from "../Implosion";
import { Camera } from "../Camera";
import { useWindowSize } from "../../../hooks";
import * as THREE from "three";

const [x0, y0, z0] = [3.15, 0.5, 0];

function Animation4Content(props: {
  canvasRef: React.RefObject<HTMLDivElement>;
}) {
  const [center, setCenter] = React.useState<number[][]>([]);
  const [camera, setCamera] = React.useState<THREE.Camera | null>(null);

  const size = useWindowSize();

  const images = document.querySelectorAll("img.who, img.who2");
  React.useEffect(() => {
    const canvas = props.canvasRef.current?.getBoundingClientRect();

    if (images && canvas && camera) {
      let vectors: number[][] = [];
      images.forEach((image) => {
        const img = image.getBoundingClientRect();
        const vector = new THREE.Vector3();
        vector.set(
          ((img.x + img.width / 2 - canvas.x) / canvas.width) * 2 - 1,
          -((img.y + img.height / 2 - canvas.y) / canvas.height) * 2 + 1,
          0
        );
        vector.unproject(camera);
        vectors.push([vector.x, vector.y, vector.z]);
      });

      setCenter(vectors);
    }
  }, [size, images]);

  useThree((asd) => {
    if (asd.camera !== camera) setCamera(asd.camera);
  });

  return (
    <>
      {center.map((p) => (
        <Implosion position={p} />
      ))}
      <Camera />
    </>
  );
}

export function Animation4() {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div ref={ref} className="animation-4">
      <Canvas>
        <Animation4Content canvasRef={ref} />
      </Canvas>
    </div>
  );
}
