import * as React from "react";

import "./styles.scss";

type DisclamerButtonProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

export function DisclamerButton(props: DisclamerButtonProps) {
  const { onClick } = props;

  const handleClick = React.useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <button className="disclamer-button" onClick={handleClick}>
      {props.children}
    </button>
  );
}
