import * as React from "react";
import "./styles.scss";

type CarouselProps = {
  children?: React.ReactNode;
};

export function Carousel(props: CarouselProps) {
  const { children } = props;
  const [activeIndex, setActiveIndex] = React.useState(0);

  const childrenLength = React.useMemo(() => {
    return React.Children.count(children);
  }, [children]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (childrenLength === activeIndex + 1) return setActiveIndex(0);
      return setActiveIndex(activeIndex + 1);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [childrenLength, activeIndex]);

  const hasPrev = activeIndex !== 0;
  const hasNext = childrenLength !== activeIndex + 1;

  const handlePrev = React.useCallback(() => {
    if (!hasPrev) return;
    return setActiveIndex(activeIndex - 1);
  }, [activeIndex, hasPrev]);

  const handleNext = React.useCallback(() => {
    if (!hasNext) return;
    return setActiveIndex(activeIndex + 1);
  }, [activeIndex, hasNext]);

  return (
    <div className="carousel">
      {React.Children.map(props.children, (child, index) => {
        if (
          index === activeIndex &&
          child &&
          typeof child === "object" &&
          "props" in child
        )
          return React.cloneElement(child, {
            ...child.props,
          });
        return null;
      })}
      <div className="carousel-controls">
        <img
          src={hasPrev ? "./icons/prev.svg" : "./icons/prev-white.svg"}
          onClick={handlePrev}
          className={!hasPrev ? "not-allowed" : undefined}
        />
        <img
          src={hasNext ? "./icons/next.svg" : "./icons/next-white.svg"}
          onClick={handleNext}
          className={!hasNext ? "not-allowed" : undefined}
        />
      </div>
    </div>
  );
}
