import * as THREE from "three";
import { extend } from "react-three-fiber";

class ImplosionMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        time: { value: 1 },
        color_r: { value: 1 },
        color_g: { value: 1 },
        color_b: { value: 1 }
      },
      vertexShader: `uniform float time;
      attribute float size;
      attribute float speed;
      varying float r;
      void main() {
        float endTime = 15.;
        float midTime = 13.;
        float midR = 4.5;
        float loopTime = mod(time, endTime) + 1.;
        
        float PI = 3.1415926538;
        float x = position.x;
        float y = position.y;
        float z = position.z;

        r = sqrt(pow(x, 2.) + pow(y, 2.) + pow(z, 2.));
        float theta = acos(z / r);
        float phi = atan(y / x);
        if (y < 0.) {
          phi = phi + PI;
        }

        if(loopTime < (endTime - midR) / speed) {
          r = endTime - speed * loopTime;
        } else {
          r = midR;
        }

        if(loopTime > midTime) {
          r = 3.1 * (loopTime - midTime) + midR;
        }

        x = r * sin(theta) * cos(phi);
        y = r * sin(theta) * sin(phi);
        z = r * cos(theta);

        vec3 pos = vec3(
          x,
          y,
          z
        );
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 2.0 );
        gl_PointSize = size;
      }`,
      fragmentShader: `uniform float time;
      uniform float color_r;
      uniform float color_g;
      uniform float color_b;
      varying float r;
      void main() {
        float endTime = 15.;
        float loopTime = mod(time, endTime) + 1.;
        float opacity = 1.;
        opacity = loopTime;

        opacity = 5. - r / 2.;

        gl_FragColor = vec4(color_r, color_g, color_b, opacity);
      }`
    });
  }
}

extend({ ImplosionMaterial });
