import * as THREE from "three";
import { extend } from "react-three-fiber";

class MyMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        color1: { value: new THREE.Vector3(0, 0, 0) },
        color2: { value: new THREE.Vector3(0, 0, 0) },
        opacity: { value: 1 },
      },
      vertexShader: `
      varying vec3 vUv; 
      void main() {
        vUv = position; 
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }`,
      fragmentShader: `
      uniform vec3 color1;
      uniform vec3 color2;
      uniform float opacity;
      varying vec3 vUv;
      void main() {
        gl_FragColor = vec4(mix(color1, color2, vUv.x), opacity);
      }`,
    });
  }
}

extend({ MyMaterial });
