import * as React from "react";

import { Animation1, Animation4 } from "./components/Animations";
import { Logo } from "./components/Logo";
import { Social } from "./components/Social";
import { Disclaimer } from "./components/Disclaimer";
import { DisclamerButton } from "./components/DisclamerButton";
import { Container, Content, Footer, Header, Nav, NavLink } from "./layout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Entrepreneurs, Home, Investment, WhoWeAre } from "./views";
import "./styles.css";
import "./loading.scss";
import "./responsive.scss";

export default function App() {
  const [loading, setLoading] = React.useState(false);
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);

  const handleShowDisclaimer = React.useCallback(() => {
    setShowDisclaimer(true);
  }, []);

  const handleCloseDisclaimer = React.useCallback(() => {
    setShowDisclaimer(false);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <BrowserRouter>
      <Container>
        {loading && <Logo center />}
        {!loading && (
          <Switch>
            <Route exact path="/" component={Animation1} />
            <Route exact path="/whoweare" component={Animation4} />
            <Route exact path="/methodology" component={Animation4} />
          </Switch>
        )}
        <Header>
          {!loading && <Logo />}
          {!loading && <Social />}
        </Header>
        {!loading && (
          <Content>
            <Nav>
              <NavLink to="/methodology">Our Scientist Entrepreneurs</NavLink>
              <NavLink to="/investment">Our Investment Focus</NavLink>
              <NavLink to="/whoweare">Our Story</NavLink>
            </Nav>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/methodology" component={Entrepreneurs} />
              <Route path="/investment" component={Investment} />
              <Route path="/whoweare" component={WhoWeAre} />
            </Switch>
            {showDisclaimer && <Disclaimer onClose={handleCloseDisclaimer} />}
          </Content>
        )}
        {!loading && !showDisclaimer && (
          <Footer>
            {!loading && <Social className="social-mobile" />}
            <label>
              Exponential Technologies Frontiers Fund Management Company LLC
              (Frontiers Capital)
            </label>
            <DisclamerButton onClick={handleShowDisclaimer}>
              Disclaimer
            </DisclamerButton>
          </Footer>
        )}
      </Container>
    </BrowserRouter>
  );
}
