import classNames from "classnames";
import * as React from "react";
import "./styles.scss";

type CarouselItemProps = {
  children?: React.ReactNode;
  className?: string;
};

export function CarouselItem(props: CarouselItemProps) {
  return (
    <div className={classNames("carousel-item", props.className)}>
      {props.children}
    </div>
  );
}
