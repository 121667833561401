import * as React from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import "./dotmaterial";
import "./explosionmaterial";

const ROTATION_SPEED = 0.7;
const SPHERE_POINTS = 200;

export function Cloud(props) {
  const ref = React.useRef();
  const ref2 = React.useRef();

  const sizes = React.useMemo(() => {
    return new Float32Array(
      new Array(SPHERE_POINTS).fill(0).reduce((r) => {
        return [...r, 2];
      }, [])
    );
  }, []);

  const points = React.useMemo(() => {
    return new Float32Array(
      new Array(SPHERE_POINTS).fill(0).reduce((r) => {
        var theta = THREE.MathUtils.randFloatSpread(360);
        var phi = THREE.MathUtils.randFloatSpread(360);

        const x = Math.sin(theta) * Math.cos(phi);
        const y = Math.sin(theta) * Math.sin(phi);
        const z = Math.cos(theta);

        return [...r, x, y, z];
      }, [])
    );
  }, []);

  useFrame((state, delta) => {
    if (ref && ref.current) {
      const time = state.clock.getElapsedTime();
      const loopTime = time % 15;

      //@ts-ignore
      ref.current.rotation.z += ROTATION_SPEED * delta;
      //@ts-ignore
      ref.current.rotation.x += ROTATION_SPEED * delta;
      //@ts-ignore
      ref.current.rotation.y += ROTATION_SPEED * delta;
      //@ts-ignore
      ref.current.material.uniforms.time.value = time;
      //@ts-ignore
      ref.current.material.uniforms.radius.value = props.radius(loopTime);

      const color = props.color(loopTime);

      //@ts-ignore
      ref.current.material.uniforms.color_r.value = color[0] / 255;
      //@ts-ignore
      ref.current.material.uniforms.color_g.value = color[1] / 255;
      //@ts-ignore
      ref.current.material.uniforms.color_b.value = color[2] / 255;
      //@ts-ignore
      ref.current.material.uniforms.opacity.value = 1;

      //@ts-ignore
      ref.current.geometry.verticesNeedUpdate = true;

      const [x, y] = props.position(loopTime);
      //@ts-ignore
      ref.current.position.x = x;
      //@ts-ignore
      ref.current.position.y = y;

      if (props.explode) {
        //@ts-ignore
        ref2.current.material.uniforms.time.value = time;
        //@ts-ignore
        ref2.current.geometry.verticesNeedUpdate = true;
      }
    }
  });

  return (
    <>
      <points ref={ref} position={props.position(0)}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={points.length / 3}
            array={points}
            itemSize={3}
          />
          <bufferAttribute
            attachObject={["attributes", "size"]}
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
        </bufferGeometry>
        <dotMaterial />
      </points>
      {props.explode && (
        <points ref={ref2} position={props.position(5)}>
          <bufferGeometry>
            <bufferAttribute
              attachObject={["attributes", "position"]}
              count={points.length / 3}
              array={points}
              itemSize={3}
            />
            <bufferAttribute
              attachObject={["attributes", "size"]}
              count={sizes.length}
              array={sizes}
              itemSize={1}
            />
          </bufferGeometry>
          <explosionMaterial />
        </points>
      )}
    </>
  );
}
