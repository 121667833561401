import * as React from "react";

type NavProps = {
  children?: React.ReactNode;
};

export function Nav(props: NavProps) {
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <nav className={open ? "open" : ""} onClick={handleClick}>
      <div className="nav-toggle">
        <span />
        <span />
        <span />
      </div>
      <div className="nav-content">{props.children}</div>
    </nav>
  );
}