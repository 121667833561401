import * as React from "react";
import { DisclamerButton } from "../DisclamerButton";
import "./styles.scss";

type DisclaimerProps = {
  onClose?: () => void;
};

export function Disclaimer(props: DisclaimerProps) {
  const { onClose } = props;

  const handleClose = React.useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <div className="disclaimer">
      <div className="disclaimer-header">
        <h3>DISCLAIMER</h3>
        <DisclamerButton onClick={handleClose}>CLOSE</DisclamerButton>
      </div>
      <div className="disclaimer-body">
        <b>ETFF MANAGEMENT CAPITAL LLC WEBSITE DISCLAIMERS AND TERMS OF USE</b>
        <br />
        <br />
        <b>GENERAL DISCLAIMER</b>
        <br />
        <br />
        This site is not intended to provide any investment, financial, legal,
        regulatory, accounting, tax or similar advice, and nothing on this site
        should be construed as a recommendation by Exponential Technologies
        Frontiers Fund Management Company LLC (“ETFF Management” or the “Firm”),
        its affiliates, or any third party, to acquire or dispose of any
        investment or security, or to engage in any investment strategy or
        transaction. An investment in any strategy involves a high degree of
        risk and there is always the possibility of loss, including the loss of
        principal. Nothing in this site may be considered as an offer or
        solicitation to purchase or sell securities or other services.
        <br />
        <br />
        <b>TERMS OF USE</b>
        <br />
        <br />
        By accessing this website and any materials presented herein (the
        “Site”), you accept in their entirety and without modification the
        following terms and conditions (“Terms of Use”) pertaining to the use of
        the Site. ETFF Management reserves the right to change the terms,
        conditions and notices under which the Site is offered without notice at
        any time. Each use of the Site constitutes your agreement to be bound by
        the then-current terms and conditions set forth in these Terms of Use.
        If you do not agree with the Terms of Use, you are not granted
        permission to access or otherwise use the Site.
        <br />
        <br />
        <b>No Offer of Securities; No Advice</b>
        <br />
        <br />
        THE SITE AND THE INFORMATION INCLUDED HEREIN IS FOR GENERAL INFORMATION
        PURPOSES ONLY. UNDER NO CIRCUMSTANCES SHOULD ANY MATERIAL ON THE SITE BE
        USED OR CONSIDERED AS AN OFFER TO SELL OR A SOLICITATION OF AN OFFER TO
        BUY ANY SECURITY, FUTURE OR OTHER FINANCIAL PRODUCT OR INSTRUMENT,
        INCLUDING ANY INTEREST IN ANY INVESTMENT FUND SPONSORED OR MANAGED BY
        ETFF MANAGEMENT OR ANY OF ITS AFFILIATES OR ANY INVESTMENT ADVISORY
        SERVICES OFFERED BY ETFF MANAGEMENT OR ITS AFFILIATES. ANY SUCH OFFER OR
        SOLICITATION CAN AND WILL BE MADE ONLY BY MEANS OF THE APPLICABLE
        CONFIDENTIAL OFFERING MEMORANDUM OF EACH SUCH INVESTMENT FUND OR OTHER
        APPLICABLE DOCUMENT, ONLY IN JURISDICTIONS IN WHICH SUCH AN OFFER WOULD
        BE LAWFUL AND ONLY TO INDIVIDUALS WHO MEET THE INVESTOR SUITABILITY AND
        SOPHISTICATION REQUIREMENTS AS DETERMINED FROM TIME TO TIME BY ETFF
        MANAGEMENT IN ITS SOLE AND ABSOLUTE DISCRETION. AN INVESTMENT IN ANY
        STRATEGY, INCLUDING THE STRATEGIES DESCRIBED HEREIN, INVOLVES A HIGH
        DEGREE OF RISK. THERE IS NO GUARANTEE THAT THE INVESTMENT OBJECTIVE WILL
        BE ACHIEVED. PAST PERFORMANCE OF THESE STRATEGIES IS NOT NECESSARILY
        INDICATIVE OF FUTURE RESULTS. THERE IS THE POSSIBILITY OF LOSS AND ALL
        INVESTMENT INVOLVES RISK INCLUDING THE LOSS OF PRINCIPAL.
        <br />
        <br />
        The Site is also not intended to provide any investment, financial,
        legal, regulatory, accounting, tax or similar advice, and nothing on the
        Site should be construed as a recommendation, by ETFF Management, its
        affiliates or any third party, to acquire or dispose of any investment
        or security, or to engage in any investment strategy or transaction. You
        should consult your own investment, legal, tax and/or similar
        professionals regarding your specific situation and any specific
        decisions.
        <br />
        <br />
        All materials on the Site are meant to be reviewed in their entirety,
        including any footnotes, legal disclaimers, restrictions, disclosures,
        hedge clauses, and any copyright or proprietary notices, including those
        contained in these Terms of Use.
        <br />
        <br />
        <b>No Reliance; No Warranty</b>
        <br />
        <br />
        While ETFF Management uses reasonable efforts to update the information
        contained on the Site, ETFF Management makes no representations or
        warranties as to the accuracy, reliability or completeness of any
        information on the Site. Any content of the Site is subject to change
        without notice. Neither the Site, nor any information presented on the
        Site, is intended as a recommendation to purchase or sell any commodity
        or security. ETFF Management has no obligation to update, modify or
        amend this Site or to otherwise notify a user thereof in the event that
        any matter stated herein, or any opinion, projection, forecast or
        estimate set forth herein, changes or subsequently becomes inaccurate.
        <br />
        <br />
        <b>Ownership</b>
        <br />
        <br />
        ETFF Management owns and maintains the Site. Except as specifically
        permitted below, nothing contained in these Terms of Use or the Site
        itself either grants or will be construed to grant to you or any third
        party, by implication, estoppel or otherwise, any title or interest in,
        or any license or right to use or reproduce, any image, text, software,
        code, trademark, logo or service mark contained in the Site, including
        without limitation the name or logo of ETFF Management, any of its
        affiliates and/or any other entity described on the Site. No act of
        downloading or otherwise copying from the Site will transfer title to
        any software or material to any user hereof. ETFF Management reserves,
        and will enforce to the fullest extent possible, all rights that it may
        have with respect to copyright and trademark ownership of all material
        contained in the Site.
        <br />
        <br />
        <b>Third Party Websites</b>
        <br />
        <br />
        ETFF Management is responsible only for the content of this Site and it
        has no control over other websites that may be accessible from this
        Site, the contents therein or the products/services offered thereby.
        Hyperlinks to or from this Site do not constitute third party
        endorsement of, sponsorship by or affiliation with ETFF Management.
      </div>
    </div>
  );
}
