import * as THREE from "three";
import { extend } from "react-three-fiber";

class DotMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        time: { value: 1 },
        radius: { value: 0 },
        color_r: { value: 1 },
        color_g: { value: 1 },
        color_b: { value: 1 },
        opacity: { value: 1 },
      },
      vertexShader: `uniform float time;
      uniform float radius;
      attribute float size;
      void main() {
        float loopTime = mod(time, 15.);
        
        float PI = 3.1415926538;
        float x = position.x;
        float y = position.y;
        float z = position.z;

        float r = sqrt(pow(x, 2.) + pow(y, 2.) + pow(z, 2.));
        float theta = acos(z / r);
        float phi = atan(y / x);

        if (y < 0.) {
          phi = phi + PI;
        }

        x = radius * sin(theta) * cos(phi);
        y = radius * sin(theta) * sin(phi);
        z = radius * cos(theta);

        vec3 pos = vec3(
          x,
          y,
          z
        );
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 2.0 );
        gl_PointSize = size;
      }`,
      fragmentShader: `uniform float time;
      uniform float color_r;
      uniform float color_g;
      uniform float color_b;
      uniform float opacity;
      void main() {
        gl_FragColor = vec4(color_r, color_g, color_b, opacity);
      }`,
    });
  }
}

extend({ DotMaterial });
