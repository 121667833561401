import { useFrame } from "@react-three/fiber";
import * as React from "react";
import * as THREE from "three";
import { hexToRgb, twoPointsCuadratic, twoPointsLine } from "../../../utils";
import "./trianglematerial";
import "./mymaterial";

function createPolygon(points, radius = 1) {
  const shape = new THREE.Shape();
  shape.moveTo(points[0][0] * radius, points[0][1] * radius);

  for (var i = 1; i < points.length; ++i) {
    shape.bezierCurveTo(
      points[i - 1][0] * radius,
      points[i - 1][1] * radius,
      0,
      0,
      points[i][0] * radius,
      points[i][1] * radius
    );
  }

  shape.bezierCurveTo(
    points[points.length - 1][0] * radius,
    points[points.length - 1][1] * radius,
    0,
    0,
    points[0][0] * radius,
    points[0][1] * radius
  );

  return shape;
}

const SHAPE_POINTS = 2000;

export function CurvedTriangle(props) {
  const { points: sourcePoints, radius } = props;
  const ref = React.useRef();
  const refMesh = React.useRef();

  const sizes = React.useMemo(() => {
    return new Float32Array(
      new Array(SHAPE_POINTS).fill(0).reduce((r) => {
        return [...r, 2];
      }, [])
    );
  }, []);

  const points = React.useMemo(() => {
    const poly = createPolygon(
      sourcePoints.map((p) => [Math.cos(p), Math.sin(p)]),
      radius
    );

    return new Float32Array(
      new Array(SHAPE_POINTS).fill(0).reduce((result) => {
        const r = THREE.MathUtils.randFloatSpread(1) + 0.5;
        const t = THREE.MathUtils.randFloatSpread(1) + 0.5;

        const [x, y] = poly.getPoint(t).toArray();
        return [...result, x, y, r];
      }, [])
    );
  }, [radius, sourcePoints]);

  useFrame((state, delta) => {
    if (ref && ref.current && refMesh && refMesh.current) {
      const time = state.clock.getElapsedTime();
      let loopTime = time % 10;

      if (time > 10) {
        loopTime = loopTime - 1;
      }

      const color = props.color(loopTime);

      refMesh.current.material.uniforms.color1.value = new THREE.Vector3(
        ...color.map((d) => d / 255)
      );
      refMesh.current.material.uniforms.color2.value = new THREE.Vector3(
        ...color.map((d) => d / 255)
      );

      if (time > 10) {
        if (loopTime <= 0) {
          refMesh.current.material.uniforms.opacity.value = twoPointsCuadratic(
            0,
            1,
            1,
            0.05
          )(loopTime + 1);
        } else {
          refMesh.current.material.uniforms.opacity.value = 0.05;
        }
      } else {
        refMesh.current.material.uniforms.opacity.value = 0.05;
      }

      if (loopTime <= 1.25) {
        ref.current.material.uniforms.opacity.value = twoPointsLine(
          -1,
          0,
          1.25,
          1
        )(loopTime);
      } else if (loopTime > 8.75) {
        refMesh.current.material.uniforms.opacity.value = twoPointsLine(
          8.75,
          0.05,
          9,
          1
        )(loopTime);
        ref.current.material.uniforms.opacity.value = twoPointsLine(
          8.75,
          1,
          9.5,
          0
        )(loopTime);
      }

      ref.current.material.uniforms.time.value = time;

      ref.current.material.uniforms.color_r.value = color[0] / 255;
      ref.current.material.uniforms.color_g.value = color[1] / 255;
      ref.current.material.uniforms.color_b.value = color[2] / 255;
      ref.current.geometry.verticesNeedUpdate = true;
    }
  });

  const polygon = React.useMemo(
    () =>
      createPolygon(
        sourcePoints.map((p) => [Math.cos(p), Math.sin(p)]),
        radius / 2
      ),
    [sourcePoints]
  );

  return (
    <>
      <points ref={ref} position={props.position}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={points.length / 3}
            array={points}
            itemSize={3}
          />
          <bufferAttribute
            attachObject={["attributes", "d"]}
            count={sizes.length}
            array={sizes.map(
              (d) => (THREE.MathUtils.randFloatSpread(1) + 0.5) * 2 * Math.PI
            )}
            itemSize={1}
          />
          <bufferAttribute
            attachObject={["attributes", "size"]}
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
        </bufferGeometry>
        <triangleMaterial />
      </points>

      <mesh ref={refMesh} position={props.position}>
        <shapeGeometry args={[polygon]} />
        <myMaterial />
      </mesh>
    </>
  );
}
