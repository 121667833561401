export function twoPointsLine(x0: number, y0: number, x1: number, y1: number) {
  const a = (y1 - y0) / (x1 - x0);
  const b = y0 - a * x0;
  return (x: number) => a * x + b;
}

export function twoPointsCuadratic(
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  warp: number = 1
) {
  let a =
    (y1 - y0 - warp * x1 + warp * x0) / (Math.pow(x1, 2) - Math.pow(x0, 2));
  let c = y0 - a * Math.pow(x0, 2) - warp * x0;
  return (x: number) => a * Math.pow(x, 2) + warp * x + c;
}

export function hexToRgb(hex: string) {
  const clean = hex.replace("#", "");
  const r = parseInt(clean.slice(0, 2), 16);
  const g = parseInt(clean.slice(2, 4), 16);
  const b = parseInt(clean.slice(4, 6), 16);
  return [r, g, b];
}

export function interpolateRgb(
  from: number[],
  to: number[],
  t0: number,
  t1: number
) {
  return (t: number) =>
    from.map((_, i) => twoPointsLine(t0, from[i], t1, to[i])(t));
}

export function findLast<T>(
  array: T[],
  predicate: (element: T, index: number, array: T[]) => boolean
) {
  return array.slice().reverse().find(predicate);
}

export function interpolateParametric(steps: [number, number][]) {
  return (t: number) => {
    const init = findLast(steps, (step) => step[0] <= t);
    const end = steps.find((step) => step[0] > t);

    if (init && end) return twoPointsLine(init[0], init[1], end[0], end[1])(t);
    return 0;
  };
}

export function interpolateParametricArray(steps: number[][]) {
  return (t: number) => {
    const init = findLast(steps, (step) => step[0] <= t);
    const end = steps.find((step) => step[0] > t);

    if (init && end)
      return init
        .slice(1)
        .map((_, i) =>
          twoPointsLine(init[0], init[i + 1], end[0], end[i + 1])(t)
        );
    return steps[0].slice(1).map((_) => 0);
  };
}

export function circleFromTangents(
  xa: number,
  ya: number,
  xb: number,
  yb: number
) {
  const x0 =
    (yb * (xa * xa + ya * (ya - yb)) - xb * xb * ya) / (xa * yb - xb * ya);

  const y0 =
    (xa * xa * xb - xa * (xb * xb + yb * yb) + xb * ya * ya) /
    (xb * ya - xa * yb);

  const r = Math.sqrt(Math.pow(xa - x0, 2) + Math.pow(ya - y0, 2));

  return {
    x: (t: number) => r * Math.cos(t) + x0,
    y: (t: number) => r * Math.sin(t) + y0,
  };
}

export function interpolateParametricShape(steps: number[]) {
  return (t: number) => {
    const ranges = steps.map((_, i) => [steps[i], steps[i + 1]]);
    const range = ranges.find(([min, max]) => min < t && max > t);

    if (range) {
      const [init, end] = range;
      if (t > init && t < end) {
        const c = circleFromTangents(
          Math.cos(init),
          Math.sin(init),
          Math.cos(end),
          Math.sin(end)
        );
        return [c.x(t + Math.PI), c.y(t + Math.PI), 0];
      }
    }
    return [0, 0, 0];
  };
}
