import * as React from "react";
import { Canvas } from "react-three-fiber";
import "./styles.scss";
import { Cloud } from "../Cloud";
import {
  hexToRgb,
  interpolateParametric,
  interpolateRgb,
  twoPointsCuadratic,
  twoPointsLine,
} from "../../../utils";
import { Camera } from "../Camera";

export function Animation1() {
  return (
    <div className="background">
      <Canvas style={{ background: "transparent" }}>
        <Cloud
          radius={(time) => {
            return interpolateParametric([
              [0, 0],
              [2, 0.8],
              [4, 2.4],
              [4.25, 0],
              [4.55, 0],
              [5.55, 2.4],
              [12, 2.4],
              [14.5, 14.9],
              [14.5, 0.1],
            ])(time);
          }}
          color={(time) => {
            const start = hexToRgb("f8a562");
            const mid = hexToRgb("FFFFFF");
            const end = hexToRgb("f8a562");

            if (time < 3.5) {
              return start;
            } else if (time < 5) {
              return interpolateRgb(start, mid, 3.5, 5)(time);
            } else if (time < 6) {
              return interpolateRgb(mid, end, 5, 6)(time);
            } else {
              return end;
            }
          }}
          position={(time) => {
            const [x0, y0, z0] = [0.5, 3, 0];
            const [x1, y1, z1] = [0, 1, 0];

            if (time < 4) {
              return [
                twoPointsCuadratic(0, x0, 4, x1, -0.8)(time),
                twoPointsLine(0, y0, 4, y1)(time),
                z0,
              ];
            } else {
              return [x1, y1, z1];
            }
          }}
          explode
        />

        <Cloud
          radius={(time) => {
            return interpolateParametric([
              [0, 0],
              [2, 0.8],
              [4, 2.4],
              [4.25, 0],
              [4.55, 0],
              [5.55, 2.4],
              [12, 2.4],
              [14.5, 14.9],
              [14.5, 0.1],
            ])(time);
          }}
          color={(time) => {
            const start = hexToRgb("ea87ab");
            const mid = hexToRgb("FFFFFF");
            const end = hexToRgb("ea87ab");

            if (time < 3.5) {
              return start;
            } else if (time < 5) {
              return interpolateRgb(start, mid, 3.5, 5)(time);
            } else if (time < 6) {
              return interpolateRgb(mid, end, 5, 6)(time);
            } else {
              return end;
            }
          }}
          position={(time) => {
            const [x0, y0, z0] = [1.5, -2, 0];
            const [x1, y1, z1] = [0, 1, 0];

            if (time < 4) {
              return [
                twoPointsCuadratic(0, x0, 4, x1, 1.1)(time),
                twoPointsLine(0, y0, 4, y1)(time),
                0,
              ];
            } else {
              return [x1, y1, z1];
            }
          }}
          explode
        />

        <Cloud
          radius={(time) => {
            return interpolateParametric([
              [0, 0],
              [2, 0.8],
              [4, 2.4],
              [4.25, 0],
              [4.55, 0],
              [5.55, 2.4],
              [12, 2.4],
              [14.5, 14.9],
              [14.5, 0.1],
            ])(time);
          }}
          color={(time) => {
            const start = hexToRgb("c759f9");
            const mid = hexToRgb("FFFFFF");
            const end = hexToRgb("c759f9");

            if (time < 3.5) {
              return start;
            } else if (time < 5) {
              return interpolateRgb(start, mid, 3.5, 5)(time);
            } else if (time < 6) {
              return interpolateRgb(mid, end, 5, 6)(time);
            } else {
              return end;
            }
          }}
          position={(time) => {
            const [x0, y0, z0] = [-2, -2.5, 0];
            const [x1, y1, z1] = [0, 1, 0];

            if (time < 4) {
              return [
                twoPointsCuadratic(0, x0, 4, x1, 0.5)(time),
                twoPointsLine(0, y0, 4, y1)(time),
                0,
              ];
            } else {
              return [x1, y1, z1];
            }
          }}
          explode
        />

        <Camera dynamic />
      </Canvas>
    </div>
  );
}
