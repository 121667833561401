import * as React from "react";
import { Carousel, CarouselItem } from "../../components/Carousel";

export function Home() {
  return (
    <Carousel>
      <CarouselItem>
        <h1>Investing in</h1>
        <h1>Scientist Entrepreneurs</h1>
        <h2>
          We combine our unparalleled network of scientists with our algorithmic
          approach
        </h2>
      </CarouselItem>
      <CarouselItem>
        <h1>Based on</h1>
        <h1>First Principles</h1>
        <h2>We focus on the frontier technologies of the future</h2>
      </CarouselItem>
      <CarouselItem>
        <h1>Pioneering Future of</h1>
        <h1> Investing</h1>
        <h2>
          World’s first public-private commingled and evergreen technology fund
        </h2>
      </CarouselItem>
    </Carousel>
  );
}
