import * as React from "react";
import * as THREE from "three";
import { useFrame,useThree } from "react-three-fiber";
import "./implosionmaterial";

const ROTATION_SPEED = 0.7;
const SPHERE_POINTS = 200;

export function Implosion(props) {
  const ref = React.useRef();

  const sizes = React.useMemo(() => {
    return new Float32Array(
      new Array(SPHERE_POINTS).fill(0).reduce((r) => {
        return [...r, 2];
      }, [])
    );
  }, []);

  const speed = React.useMemo(() => {
    return new Float32Array(
      new Array(SPHERE_POINTS).fill(0).reduce((r) => {
        return [...r, (THREE.MathUtils.randFloatSpread(1) + 0.5) * 5 + 1];
      }, [])
    );
  }, []);

  const points = React.useMemo(() => {
    return new Float32Array(
      new Array(SPHERE_POINTS).fill(0).reduce((r) => {
        var theta = THREE.MathUtils.randFloatSpread(360);
        var phi = THREE.MathUtils.randFloatSpread(360);

        const x = Math.sin(theta) * Math.cos(phi);
        const y = Math.sin(theta) * Math.sin(phi);
        const z = Math.cos(theta);

        return [...r, x, y, z];
      }, [])
    );
  }, []);


  useFrame((state, delta) => {
    if (ref && ref.current) {
      const time = state.clock.getElapsedTime();
      ref.current.material.uniforms.time.value = time;

      //@ts-ignore
      ref.current.rotation.z += ROTATION_SPEED * delta;
      //@ts-ignore
      ref.current.rotation.x += ROTATION_SPEED * delta;
      //@ts-ignore
      // ref.current.rotation.y += ROTATION_SPEED * delta;

      ref.current.geometry.verticesNeedUpdate = true;
    }
  });

  return (
    <>
      <points ref={ref} position={props.position}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={points.length / 4}
            array={points}
            itemSize={4}
          />
          <bufferAttribute
            attachObject={["attributes", "speed"]}
            count={speed.length}
            array={speed}
            itemSize={1}
          />
          <bufferAttribute
            attachObject={["attributes", "size"]}
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
        </bufferGeometry>
        <implosionMaterial />
      </points>
    </>
  );
}
