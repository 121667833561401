import React from "react";
import "./styles.scss";

type ScientistCardProps = {
  title: string;
  caption: string;
  author: string;
  occupation: string;
  image: string;
  isLeftSide?: boolean;
};

export function ScientistCard({
  author,
  caption,
  image,
  isLeftSide,
  occupation,
  title,
}: ScientistCardProps) {
  return (
    <article className={`card-container ${isLeftSide ? "left" : "right"}`}>
      <img src={image} alt={author} className="who2" />
      <div className="card-content">
        <div className="border-box" />
        <h3>{title}</h3>
        <div className="paragraph-container">
          <div className="border-box" />
          <p>{caption}</p>
        </div>
        <div className="flex">
          <p className="author">
            {author}
            <span className="occupation">{` (${occupation})`}</span>
          </p>
        </div>
      </div>
    </article>
  );
}
