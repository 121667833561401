import * as THREE from "three";
import { extend } from "react-three-fiber";

class ExplosionMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        time: { value: 1 },
        color_r: { value: 1 },
        color_g: { value: 1 },
        color_b: { value: 1 }
      },
      vertexShader: `uniform float time;
      attribute float size;
      void main() {
        float loopTime = mod(time, 15.);
        
        if ((loopTime < 8.) && (loopTime > 4.55)) {
          float PI = 3.1415926538;
          float x = position.x;
          float y = position.y;
          float z = position.z;
  
          float r = sqrt(pow(x, 2.) + pow(y, 2.) + pow(z, 2.));
          float theta = acos(z / r);
          float phi = atan(y / x);
  
          if (y < 0.) {
            phi = phi + PI;
          }
  
          r = 10. * (loopTime - 4.55);
  
          x = r * sin(theta) * cos(phi);
          y = r * sin(theta) * sin(phi);
          z = r * cos(theta);
  
          vec3 pos = vec3(
            x,
            y,
            z
          );
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 2.0 );
          gl_PointSize = size;
        } else {
          vec3 pos = vec3(
            0.,
            0.,
            0.
          );
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 2.0 );
          gl_PointSize = 0.;
        }

      }`,
      fragmentShader: `uniform float time;
      uniform float color_r;
      uniform float color_g;
      uniform float color_b;
      void main() {
        float loopTime = mod(time, 15.);
        float opacity = 1.;

        if (loopTime > 5.35) {
          opacity = 1. - (loopTime - 5.35);
        }

        gl_FragColor = vec4(color_r, color_g, color_b, opacity);
      }`
    });
  }
}

extend({ ExplosionMaterial });
